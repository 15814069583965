import { useMemo, useEffect } from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { useTranslation } from "react-i18next"
import { useLocalization, LocalizedLink as Link } from "gatsby-theme-i18n"
import { isAfter, isBefore, toDate, parseISO } from "date-fns"

import { NewsByTypeTemplate } from "templates/newsByType"
import { NewsNavi } from "components/NewsNavi"
import Footer from "components/Footer"
import { PageTopAnchor } from "components/PageTopAnchor"
import { PcFooterLink } from "components/FooterLink/Pc"
import { MoFooterLink } from "components/FooterLink/Mo"
import { MoFooterWrap } from "components/FooterWrap"
import { PhoneArea } from "components/PhoneArea"
import { BannerArea } from "components/BannerArea"
import { newsTypeMap } from "utils"
import { NoNewsFound } from "components/NoNewsFound"
import { useCmsContext } from "context/Cms"

const NewsIndexPageByType = ({ data, pageContext }) => {
  const newsType = pageContext.newsMeta__type
  const { article } = data

  const { changeShowButton } = useCmsContext()
  const { locale } = useLocalization()
  const { t } = useTranslation()

  const now = toDate(Date.now())

  const localizedArticle = useMemo(
    () =>
      article?.edges.filter(
        ({ node }) =>
          node.node_locale === locale && node.newsContent.newsContent !== "",
      ) || [],
    [article],
  )

  const publishedArticle = useMemo(
    () =>
      localizedArticle?.filter(({ node }) => {
        const a = isBefore(now, parseISO(node.newsMeta.end_publish_datetime))
        const b = isAfter(now, parseISO(node.newsMeta.start_publish_datetime))

        return a && b
      }) || [],
    [localizedArticle],
  )

  const showNewsList = useMemo(() => {
    if (publishedArticle.length === 0) return <NoNewsFound />
    if (localizedArticle.length === 0) return <NoTranslationFound />

    return <NewsByTypeTemplate data={publishedArticle} />
  }, [localizedArticle, publishedArticle])

  useEffect(() => {
    const enElement =
      article?.edges.filter(
        ({ node }) =>
          node.node_locale === "en" && node.newsContent.newsContent !== "",
      ) || []

    const showButton =
      locale === "en"
        ? true
        : publishedArticle.length > 0 && enElement.length > 0

    changeShowButton(showButton)
  }, [article?.edges, publishedArticle])

  return (
    <main>
      <Helmet title={t("ニュース", "ニュース")} />
      <div className="sub_page">
        <section className="page_header news inviewfadeInUp">
          <div className="container2">
            <h1>{t("ニュース一覧")}</h1>
          </div>
        </section>

        <div className="container3">
          <section className="breadcrumb">
            <ul>
              <li>
                <Link to="/">{t("星井眼科医院TOP", "星井眼科医院TOP")}</Link>
              </li>
              <li>
                {t(newsTypeMap[newsType].label, newsTypeMap[newsType].label)}
              </li>
            </ul>
          </section>

          <section className="content">
            <div className="main_area inviewfadeInUp">
              <div className="news_block inviewfadeInUp">
                <h2>
                  {t(newsTypeMap[newsType].label, newsTypeMap[newsType].label)}
                </h2>
                <div className="usual_news">{showNewsList}</div>
              </div>
            </div>
            <NewsNavi />
          </section>
        </div>
      </div>
      <div className="container2">
        <section className="content">
          <PhoneArea />
          <BannerArea />
        </section>
      </div>
      <section className="content">
        <div className="footer_link inviewfadeInUp">
          <PcFooterLink />
          <MoFooterLink />
        </div>
      </section>

      <Footer />
      <PageTopAnchor />
      <MoFooterWrap />
    </main>
  )
}

export const query = graphql`
  query NewsByType($newsMeta__type: String) {
    article: allContentfulArticle(
      limit: 100
      filter: { newsMeta: { type: { eq: $newsMeta__type } } }
      sort: { order: DESC, fields: newsMeta___post_date }
    ) {
      edges {
        node {
          id
          contentful_id
          title
          newsMeta {
            post_date
            start_publish_datetime
            end_publish_datetime
            type
          }
          newsContent {
            newsContent
          }
          node_locale
        }
      }
    }
  }
`

export default NewsIndexPageByType
